<template>
  <div class="orga_user_card" @click="userSelected">
    <v-row class="align-center">
      <v-col cols="12" md="3" class="text-center">
        <v-avatar color="red" size="48">
          <img
            v-if="hasAvatar(user.UTI_Utilisateur.DOC_Documents)"
            :src="hasAvatar(user.UTI_Utilisateur.DOC_Documents)"
          />
          <span v-else class="white--text font-weight">{{
            getInitialUser(user.UTI_Utilisateur)
          }}</span>
        </v-avatar>
      </v-col>

      <v-col cols="12" md="9">
        <div class="font-weight-medium">
          {{ user.UTI_Utilisateur.CT_Prenom }} {{ user.UTI_Utilisateur.CT_Nom }}
        </div>
        <div class="orga_user_card-fonction">
          {{ user.FonctionUtilisateur.NomFonction }},
          <span v-if="user.DescriptionFonction">{{
            user.DescriptionFonction
          }}</span>
        </div>
        <div class="orga_user_card-fonction">
          {{ user.STR_Structure.NomStructure }}
        </div>
        <div class="orga_user_card-fonction">
          <a :href="`mailto:${user.UTI_Utilisateur.CT_Email ? user.UTI_Utilisateur.CT_Email : user.UTI_Utilisateur.CT_Email2}`">
            {{ user.UTI_Utilisateur.CT_Email ? user.UTI_Utilisateur.CT_Email : user.UTI_Utilisateur.CT_Email2 }}
          </a>
        </div>
        <div class="orga_user_card-fonction">
          <a :href="`tel:${user.UTI_Utilisateur.CT_TelephoneMobile ? user.UTI_Utilisateur.CT_TelephoneMobile : user.UTI_Utilisateur.CT_TelephoneFixe}`">
            {{ user.UTI_Utilisateur.CT_TelephoneMobile ? user.UTI_Utilisateur.CT_TelephoneMobile : user.UTI_Utilisateur.CT_TelephoneFixe }}
          </a>
        </div>
        <div v-if="!readOnly && hasCodeFederal" class="orga_user_card-fonction">
          <a @click.prevent="confirmRemoveRole">Supprimer</a>
        </div>
      </v-col>

      <modalConfirm
        :showDialog="modalConfirmIsOpen"
        @answerYes="removeRole"
        @answerNo="modalConfirmIsOpen = false"
      ></modalConfirm>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalConfirm from "@/components/utils/modals/yes-no.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
export default {
  components: { modalConfirm },

  data: () => ({
    modalConfirmIsOpen: false,
  }),

  computed: {
    ...mapGetters("structure", ["hasCodeFederal"]),
  },

  methods: {
    ...mapActions("structure", ["updateUserFonctionOnStructure"]),

    hasAvatar(doc) {
      if (!doc[0]) return false;
      return doc[0].CheminDocument;
    },

    getInitialUser(user) {
      return (
        user.CT_Nom.substring(0, 1).toUpperCase() +
        user.CT_Prenom.substring(0, 1).toUpperCase()
      );
    },

    userSelected() {
      this.$emit("onSelected", this.user);
    },

    async confirmRemoveRole() {
      this.modalConfirmIsOpen = true;
    },

    async removeRole() {
      try {
        let payload = {
          Structure_ID: this.user.ID_Structure,
          Utilisateur_ID: this.user.UTI_Utilisateur.id,
          Fonction_ID: null,
          FonctionDescription: "",
        };
        await this.updateUserFonctionOnStructure(payload);
        this.modalConfirmIsOpen = false;
        this.$emit("onUpdated", this.user);
        this.$emit("onDeleted", this.user);
        success_notification("Organigramme mis à jour");
      } catch (e) {
        error_notification("Oups, un erreur est survenue");
      }
    },
  },

  props: {
    user: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.orga_user_card {
  margin: 10px 0;
  .orga_user_card-fonction {
    span {
      font-size: 0.8rem;
      font-style: italic;
      color: gray;
    }
  }
}
</style>
