<template>
  <Loader v-if="!mounted" fixed />

  <v-row v-else no-gutters style="margin: -30px 0">
    <v-col cols="12" sm="4" lg="4">
      <v-row no-gutters class="h-100">
        <v-col
          cols="12"
          class="p-4 pl-0"
          style="border-right: 1px solid #f2f2f2"
        >
          <OrganigrammeStructure
            :structure_id="structureID"
            @onSelected="userSelected"
            @onDeleted="onUserDeleted"
            :key="OrganigrammeKey"
            ref="organigramme"
            :context="{
              ID_Structure: structureID,
              ID_Saison: currentSaison.id,
            }"
          ></OrganigrammeStructure>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="8" lg="8" class="p-4" style="max-width: 400px">
      <ValidateForm
        class="assgin_orga"
        @validated="submit"
        :loading="loading"
        :showFieldObl="false"
      >
        <div class="mb-6">
          <h6 class="mb-0">Ajouter une personne dans l'organigramme:</h6>
        </div>
        <div class="mb-2">
          <h6 class="mb-0">Poste*</h6>
          <InputSelect
            rules="required"
            :disabled="!hasCodeFederal"
            v-model="currentFonctionSelected"
            :items="allFonction"
            item_text="NomFonction"
            item_value="id"
          ></InputSelect>
        </div>

        <div class="mb-2">
          <h6 class="mb-0">Complément de définition</h6>
          <InputText
            :disabled="!hasCodeFederal"
            v-model="descriptionFonction"
            twoLines
            rules="min:0|max:50"
          ></InputText>
        </div>

        <div class="mb-2">
          <h6 class="mb-0">Nom du dirigeant*</h6>
          <v-row no-gutters>
            <v-col cols="11">
              <InputText
                custom_class="input_user_dis"
                v-model="currentUserSelectedName"
                rules="required|min:0|max:250"
                disabled
              ></InputText
            ></v-col>

            <v-col class="button-action" cols="1" v-if="hasCodeFederal">
              <v-btn
                class="my-2 mx-2"
                fab
                x-small
                dark
                color="#1976d2"
                @click="openSelectUserModal"
              >
                <!-- <v-icon dark>mdi-plus</v-icon> -->
                <v-icon dark>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                class="my-2 mx-2"
                fab
                x-small
                dark
                color="#1976D2"
                @click.prevent="routeToAddUser"
              >
                <!-- <v-icon dark>mdi-plus</v-icon> -->
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-btn
          type="submit"
          :disabled="
            !currentFonctionSelected ||
            !currentUserSelectedName ||
            !hasCodeFederal
          "
          rounded
          large
          :loading="loading"
          class="btn btn-primary"
        >
          Mettre à jour
        </v-btn>
      </ValidateForm>
    </v-col>
    <ModalUsers
      v-if="userModalIsOpen"
      :context="{
        ID_Structure: structureID,
        ID_Saison: this.currentSaison.id,
      }"
      :newUser="newUser"
      :isOpen="userModalIsOpen"
      @onClose="userModalIsOpen = false"
      @onUserSelected="setUser"
    ></ModalUsers>
    <ModalCreateUser
      v-if="createUserModalIsOpen"
      :isOpen="createUserModalIsOpen"
      @onCreated="onUserCreated"
      :context="{
        ID_Structure: structureID,
        ID_Saison: currentSaison.id,
      }"
      @onClose="createUserModalIsOpen = false"
    />
  </v-row>
</template>

<script>
import Loader from "@/components/LoadingIndicator";
import OrganigrammeStructure from "@/components/Structure/Organigramme/Organigramme.vue";
import ValidateForm from "@/components/utils/form.vue";
import InputSelect from "@/components/utils/select.vue";
import InputText from "@/components/utils/input.vue";
import ModalUsers from "@/components/utils/modals/users.vue";
import ModalCreateUser from "@/components/utils/modals/User/User__CreateUser.vue";
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";

import { mapGetters, mapActions } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: {
    Loader,
    OrganigrammeStructure,
    ValidateForm,
    InputSelect,
    InputText,
    ModalUsers,
    ModalCreateUser,
  },

  data: () => ({
    mounted: false,
    selectedUser: "",
    descriptionFonction: "",
    currentUserSelectedName: "",
    currentFonctionSelected: null,
    // currentStructureMembers: [],
    userModalIsOpen: false,
    loading: false,
    OrganigrammeKey: 0,
    structureInfo: null,
    typeStructureList: null,
    isFederalStructure: false,
    listFonction: null,
    createUserModalIsOpen: false,
    newUser: null,
    structureID: null,
  }),

  async created() {
    await this.setData();
    console.log("getStructure===>>>", this.currentStructureId);

    // this.currentStructureMembers = this.getStructure(this.currentStructureId).UTI_Utilisateurs.filter(user => user.ID_Fonction)

    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureId",
      "getStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("saisons", ["currentSaison"]),
  },

  methods: {
    ...mapActions("basics", ["getListTypeFonctions", "getListStructureType"]),
    ...mapActions("structure", [
      "getStructureMembre",
      "updateUserFonctionOnStructure",
      "createUserFonctionOnStructure",
      "getStructureByID",
    ]),
    ...mapActions("utilisateurs", [
      "getUtlisateursInStructure",
      "updateUser",
      "userStructure",
      "getUtilisateur",
    ]),

    async setData() {
      this.structureID = parseInt(this.$route.params.id);
      if (!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);

      this.listFonction = await this.getListTypeFonctions();

      // this.allFonction = await this.getListTypeFonctions();
      this.structureInfo = await this.getStructureByID(this.structureID);
      this.typeStructureList = await this.getListStructureType();
      const typefederal = this.typeStructureList?.filter(
        (type) =>
          type.NomTypeStructure === "Ligue" ||
          type.NomTypeStructure === "Comité territorial" ||
          type.NomTypeStructure === "Service fédéral FFME"
      );
      this.isFederalStructure = typefederal?.some(
        (type) => type.id === this.structureInfo.ID_TypeStructure
      );
      // const fonctionNUll= this.listFonction?.filter(list=> )
      console.log("this.isFederalStructure===>>", this.isFederalStructure);
      this.allFonction = this.isFederalStructure
        ? this.listFonction.filter(
            (list) =>
              list.EST_FonctionFederal === null ||
              list.EST_FonctionFederal === true
          )
        : this.listFonction.filter(
            (list) =>
              list.EST_FonctionFederal === null ||
              list.EST_FonctionFederal === false
          );
      return;
    },

    userSelected(user) {
      if (user) {
        this.selectedUser = user;
        this.currentUserSelectedName =
          user.UTI_Utilisateur.CT_Prenom + " " + user.UTI_Utilisateur.CT_Nom;
        this.descriptionFonction = user.DescriptionFonction;
        this.currentFonctionSelected = user.ID_Fonction;
      }
      this.userModalIsOpen = false;
    },
    setUser(user) {
      if (user) {
        this.selectedUser = user;
        this.currentUserSelectedName =
          user.UTI_Utilisateur.CT_Prenom + " " + user.UTI_Utilisateur.CT_Nom;
      }
      this.userModalIsOpen = false;
    },

    // fonctionSelected(item_id) {
    //   // console.log(item_id);

    //   this.descriptionFonction = this.allFonction.find(
    //     (fct) => fct.id == item_id
    //   ).NomFonction;
    //   // let user = this.currentStructureMembers.find(usr => usr.ID_Fonction == item.id)
    //   // this.userSelected(user)
    // },
    routeToAddUser() {
      window.open(`${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-structure/utilisateurs/nouvel?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`, '_blank');
    },

    openSelectUserModal() {
      if (this.hasCodeFederal) {
        this.userModalIsOpen = true;
      }
    },

    async submit() {
      let payloadStruct = {};
      let arrayClause = {
        EST_Actif: { _eq: true },
      };
      arrayClause.ID_Utilisateur = { _eq: this.selectedUser.ID_Utilisateur };
      payloadStruct.where = { ...arrayClause };
      let structureUser = await this.getStructureMembre(payloadStruct);

      const idSTR_StructureUtilisateur = structureUser.find(
        (str) => str.ID_Structure === this.currentStructureId
      );

      try {
        if (idSTR_StructureUtilisateur) {
          let payload = {
            id: idSTR_StructureUtilisateur.id,
            ID_Structure: this.currentStructureId,
            ID_Utilisateur: this.selectedUser.ID_Utilisateur,
            ID_Fonction: this.currentFonctionSelected,
            DescriptionFonction: this.descriptionFonction,
          };

          await this.updateUserFonctionOnStructure(payload);
        } else {
          let payload = {
            ID_Structure: this.currentStructureId,
            ID_Utilisateur: this.selectedUser.ID_Utilisateur,
            ID_Fonction: this.currentFonctionSelected,
            DescriptionFonction: this.descriptionFonction,
            EST_Actif: true,
            EST_RoleFederal: true,
          };

          await this.createUserFonctionOnStructure(payload);
        }

        success_notification("Organigramme mis à jour");
        this.resetForm();
        await this.$refs.organigramme.onUpdated();
        this.OrganigrammeKey += 1;
      } catch (e) {
        error_notification(
          "Merci de spécifier un poste avant l'enregistrement"
        );
      }
    },

    resetForm() {
      this.selectedUser = {};
      this.currentUserSelectedName = "";
      this.descriptionFonction = "";
      this.currentFonctionSelected = {};
    },

    onUserDeleted() {
      this.resetForm();
    },

    capitalizeFirstLetter(string) {
      let str = string.split(" ");
      for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }
      return str.join(" ");
    },

    async onUserCreated(user) {
      const currentLicenceNumero = await this.getUtilisateur({
        ID_Utilisateur: user.id,
      });
      let payload = {
        id: user.id,
        CT_Email: user.Email,
        CT_EST_Civilite: user.CT_EST_Civilite,
        ID_PaysNationalite: user.ID_PaysNationalite,
        CT_Nom: trimAndUppercaseAllLetters(user.CT_Nom),
        CT_Prenom: trimAndCapitalizeFirstLetter(user.CT_Prenom),
        DN_DateNaissance: user.DN_DateNaissance,
        DN_CommuneNaissance: user.DN_CommuneNaissance,
        DN_CommuneNaissance_CodeInsee: user.DN_CommuneNaissance_CodeInsee,
        CT_TelephoneMobile: user.CT_TelephoneMobile,
        CT_TelephoneFixe: user.CT_TelephoneFixe,
        LicenceNumero: currentLicenceNumero.LicenceNumero,
        STR_StructureUtilisateurs: {
          data: {
            EST_Actif: true,
            EST_RoleFederal: false,
            EST_Salarier: false,
            EST_StructureLicencie: true,
            ID_Structure: this.structureID,
          },
        },
      };

      try {
        let user = await this.updateUser({ user: payload });
        let payloadUserStructure = {
          ID_Utilisateur: user?.id,
          ID_Structure: user?.STR_StructureUtilisateurs[0]?.STR_Structure?.id,
        };
        let newUserStructure = await this.userStructure(payloadUserStructure);
        this.newUser = newUserStructure;
        success_notification("Nouvel utilisateur enregistré");
        await this.setData();
        this.createUserModalIsOpen = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
        console.log(e);
      }

      // console.log(payload);
      return;
    },
  },
};
</script>

<style lang="scss">
.assgin_orga {
  .input_user_dis {
    background: transparent !important;
  }
}
.button-action {
  display: flex;
}
</style>
